.periodlic-group-btn {
  background-color: #104346;
  color: whitesmoke;
}

.periodlic-group-btn .btn {
  flex: 1;
  padding: 6px 12px;
  font-size: 12px;
  transition: background-color 0.3s;
  font-weight: bold;
}

.periodlic-group-btn > .btn:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.periodlic-group-btn > .btn:not(:first-child) {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.periodlic-group-btn button.btn:hover,
.periodlic-group-btn button.periodlic-active {
  background-color: #ffbe00 !important;
  border-radius: var(--bs-border-radius);
}

.periodlic-active {
  background-color: #ffbe00 !important;
}

.header-container {
  display: flex;
  align-items: center;
}

.profit-icon {
  font-size: 20px;
  margin-right: 5px;
}

.card-header-text {
  font-weight: bold;
}

.energy-icon {
  font-size: 20px;
  margin-right: 5px;
}

.divider {
    width: 1px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
  }
