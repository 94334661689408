#login-title {
    text-align: center;
    font-size: 30px;
    font-weight: bolder;
    margin: 5px;
    color: #328993;
}

.login-btn {
    background-color: #328993;
    color: whitesmoke;
    font-weight: bold;
}

.login-btn:hover {
    opacity: 0.85;
}

.form-label, .form-text {
    color: #2b3035;
}

#login-div {
    background-color: whitesmoke;
}

form {
    margin-top: 30px;
}
