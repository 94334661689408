.main-navbar {
    position: fixed;
    width: 100%;
    height: 50px;
    margin: 0;
    padding: 0;
    top: 0;
    z-index: 1;
    background-color: #104346 !important;
    border-bottom: 1px solid #545b64;
}

.main-navbar img {
    margin-right: 10px;
}

#brand-name, #login-link {
    color: whitesmoke;
}

h3 {
    margin-bottom: 0;
}

#auth-dropdown, #farm-dropdown {
    background: none !important;
    border: none !important;
    color: #ffffff !important;
}

.dropdown-menu {
    left: unset !important;
}

.navbar-toggler {
    box-shadow: none !important;
}

#responsive-navbar-nav {
    position: relative;
    height: fit-content;
    background-color: #104346;
    opacity: 0.9;
    margin: 0px -20px;
}

#responsive-navbar-nav #flex-nav {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
    align-items: flex-start;
}
