.device-card .hover-reveal {
    visibility: hidden;
    height: 0;
    min-height: 0;
    transition: visibility 0s, min-height 0.5s;
}

.device-card:hover .hover-reveal {
    visibility: visible;
    height: fit-content;
    min-height: 50px;
}

.popover {
    max-width: 400px;
}

.modal-dialog {
    max-width: 80%;
}

.normal-state {
    color: green !important;
}

.error-state {
    color: red !important;
}