#farm-side-bar {
    background-color: whitesmoke;
    transition: width 0.5s;
}

#farm-detail-outlet {
    transition: width 0.5s;
}

.col-sm-1 {
    width: 6%;
}

.col-sm-11 {
    width: 94%;
}

.side-btn {
    border: none;
    border-radius: 0;
    color: #2b3035;
}

.side-btn:hover {
    background-color: #FFBE00;
    color: whitesmoke;
}

.toggle-sidebar.side-btn {
    background-color: unset;
}

.toggle-sidebar.btn:hover {
    background-color: #FFBE00;
}

.side-bar-active {
    background-color: #104346;
    color: whitesmoke;
}