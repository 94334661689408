.weather-group {
    color: #ffffff;
}

.weather-group > .col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 80px;
}

@media only screen and (max-width: 571.33px) {
    #weather-device-view {
        order: 3;
        display: flex;
        justify-content: space-around;
        flex-grow: 1;
    }
}