#footer {
    height: 26px;
    position: fixed;
    width: 100%;
    margin: 0 !important;
    padding: 0;
    bottom: 0;
    z-index: 1;
    left: 0;
    background-color: #2b3035;
    opacity: 0.85  ;
    color: whitesmoke;
    border-top: 1px solid #545b64;
}
.footer-text {
    font-size: 12px;
}
