.sub-navbar {
    height: 40px;
    position: fixed;
    width: 100%;
    margin: 0;
    padding: 0;
    top: 50px;
    z-index: -1;
}

.sub-navbar-text {
    font-size: 16px;
    padding-left: 20px;
}

.breadcrumb {
    margin: 0;
}

.breadcrumb-item > a{
    text-decoration: none;
    color: #2b3035;
    font-weight: bolder;
}

.breadcrumb-item > a:hover {
    color: #54585a;
}

.breadcrumb-item.active {
    color: #54585a;
    font-weight: bolder;
}