.footer-navbar {
    height: 50px;
    position: fixed;
    width: 100%;
    margin: 0 !important;
    padding: 0;
    bottom: 0;
    z-index: 1;
    left: 0;
}

.footer-text {
    font-size: 13px;
}